import { InMemoryCache } from 'apollo-boost';
import fetch from 'isomorphic-unfetch';
import AWS_AppSync from '../AppSync';
import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { ComplexObjectLink } from '../apollo-links/complex-objects-link';
import { getAuthSession, setDevProvidedAuth } from '../lib/cognito';
import { logout } from "@/lib/utils";

export let apolloClient = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

//https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
function create(initialState, dev_provided_token = null) {
  const url = AWS_AppSync.graphqlEndpoint;
  const region = AWS_AppSync.region;
  const timelineUrl = AWS_AppSync.timelineServiceUrl;
  const notesServiceUrl = AWS_AppSync.notesServiceUrl

  if (dev_provided_token) {
    setDevProvidedAuth(dev_provided_token);
  }

  // https://aws-amplify.github.io/docs/js/api#manual-configuration
  const auth = {
    type: 'API_KEY',
    apiKey: AWS_AppSync.apiKey,
    // Amazon Cognito Federated Identities using AWS Amplify credentials: () => Auth.currentCredentials(),
    // Amazon Cognito user pools using AWS Amplify
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => {
      try {
        const result = await getAuthSession();
        return result.getIdToken().getJwtToken();
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  };

  /*
  Providing a custom fetch function that is passed to the Apollo Client constructor.
  This helps us to intercept the response from the server and check if the response
  is a 401. If it is a 401, and the operation is not for viewer, we log the user out.
  We need to ignore viewer as the login screen makes a viewer query unconditionally.
  So, if we do not ignore viewer, this enters an infinte loop.
  */
  const customFetch = (uri, options) => {
    return new Promise(async (resolve, reject) => {
      const result = await fetch(uri, options);
      const requestBody = JSON.parse(options.body);
      const resultBody = await result.clone().json();

      if (
        (result.status === 401 && requestBody.operationName !== 'viewer') ||
        (result.status === 200 && resultBody.errors?.[0]?.message?.toUpperCase().includes('USER IS INACTIVE'))
      ) {
        console.log('Force Logging out now'); 
        await logout(apolloClient);
        
        return;
      }

      if (result.status >=200 && result.status < 300) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  }

  const httpLink = createHttpLink({ uri: url, fetch: customFetch });
  const timelineLink = new createHttpLink({ uri: timelineUrl });
  const notesServiceLink = new createHttpLink({ uri : notesServiceUrl});

  const setAuthorizationLink = setContext((request, previousContext) => ({
    headers: {
      httpOnly: true,
      secure: true,
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
    },
  }));

  const claireAuthLink = createAuthLink({
    url,
    region,
    auth,
  });

  const timelineAuthLink = setContext((_,{ token }) => {
    return {
      headers: {
        httpOnly: true,
        secure: true,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        authorization: `Bearer ${token}`,
      },
    };
  });

  const timelineLinkObject = ApolloLink.from([
    timelineAuthLink,
    new ComplexObjectLink(() => Auth.currentCredentials()),
    createSubscriptionHandshakeLink(timelineUrl, timelineLink),
  ]);

  const notesAuthLink = setContext((_,{ token }) => {
    return {
      headers: {
        httpOnly: true,
        secure: true,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        authorization: `Bearer ${token}`,
      },
    };
  });

  const notesLinkObject = ApolloLink.from([
    notesAuthLink,
    new ComplexObjectLink(() => Auth.currentCredentials()),
    createSubscriptionHandshakeLink(notesServiceUrl, notesServiceLink),
  ]);

  const clairelink = ApolloLink.from([
    setAuthorizationLink,
    claireAuthLink,
    new ComplexObjectLink(() => Auth.currentCredentials()),
    createSubscriptionHandshakeLink(url, httpLink),
  ]);

  const client = new ApolloClient({
    ssrMode: false,
    link: ApolloLink.split(
      operation => operation.getContext().clientName === 'timeline',
      timelineLinkObject,
      ApolloLink.split(
        operation => operation.getContext().clientName === 'notesService',
        notesLinkObject,
        clairelink
      )
    ),
    cache: new InMemoryCache(),
  });
  return client;
}

export default function initApollo(initialState, dev_provided_token = null) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, dev_provided_token);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, dev_provided_token);
  }

  return apolloClient;
}
